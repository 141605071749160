<!--
#***********************************************
#
#      Filename: src/views/List/MerchantList.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 商户名单
#        Create: 2021-10-28 16:40:15
# Last Modified: 2021-11-29 18:03:01
#***********************************************
-->
<template>
  <div class="app-container">
    <!-- 数据筛选 -->
    <ContentHeader
      class="m-b-20"
      :settings="settings"
      :table-head-fields.sync="tableHeadFields"
      :total-item="total"
      :permissions="permissions"
      @filter-change="filterChange"
      @add="add('新增商户名单')"
      @refresh="handleRefresh"
      @export="handleExport"
      @batch-delete="handleBatchDelete('批量删除名单', handleBatchDel)" />
    <!-- end 数据筛选 -->

    <!-- 数据Table -->
    <el-table :data="dataList" stripe border size="mini" @selection-change="changeSelectItems">
      <el-table-column fixed="left" type="expand" :width="50" align="center">
        <template slot-scope="props">
          <el-table border style="width: 100%" :data="props.row.refShops" size="medium">
            <el-table-column label="商铺全号" prop="fullNumber" width="200" />
            <el-table-column label="楼栋ID" prop="buildingId" width="200" />
            <el-table-column label="商铺面积" prop="areaTotal" width="200" />
            <el-table-column label="楼层" prop="floor" width="200" />
            <el-table-column label="单元号" prop="unit" width="200" />
            <el-table-column label="操作" :width="100" align="center">
              <template slot-scope="scope">
                <div class="gz-text-btn-group">
                  <div
                    class="gz-text-btn gz-text-btn-yellow"
                    @click="edit(scope.row, '作废签约', beforeEditDiscard(scope.row, 3))">
                    作废
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column fixed="left" type="selection" :width="50" align="center" />
      <el-table-column
        v-if="tableHeadFields[0].isShow"
        :label="tableHeadFields[0].label"
        :width="tableHeadFields[0].width"
        :align="tableHeadFields[0].align"
        :prop="tableHeadFields[0].prop" />
      <el-table-column
        v-if="tableHeadFields[1].isShow"
        :label="tableHeadFields[1].label"
        :width="tableHeadFields[1].width"
        :align="tableHeadFields[1].align"
        :prop="tableHeadFields[1].prop" />
      <el-table-column
        v-if="tableHeadFields[2].isShow"
        :label="tableHeadFields[2].label"
        :width="tableHeadFields[2].width"
        :align="tableHeadFields[2].align"
        :prop="tableHeadFields[2].prop" />
      <el-table-column
        v-if="tableHeadFields[3].isShow"
        :label="tableHeadFields[3].label"
        :width="tableHeadFields[3].width"
        :align="tableHeadFields[3].align"
        :prop="tableHeadFields[3].prop">
        <template slot-scope="scope">
          <span v-if="scope.row.mainBind" class="text-light-blue">主选区域</span>
          <span v-else class="text-green">附属区域</span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="tableHeadFields[4].isShow"
        :label="tableHeadFields[4].label"
        :width="tableHeadFields[4].width"
        :align="tableHeadFields[4].align"
        :prop="tableHeadFields[4].prop" />
      <el-table-column
        v-if="tableHeadFields[5].isShow"
        :label="tableHeadFields[5].label"
        :width="tableHeadFields[5].width"
        :align="tableHeadFields[5].align"
        :prop="tableHeadFields[5].prop" />
      <el-table-column
        v-if="tableHeadFields[6].isShow"
        :label="tableHeadFields[6].label"
        :width="tableHeadFields[6].width"
        :align="tableHeadFields[6].align"
        :prop="tableHeadFields[6].prop" />
      <el-table-column
        v-if="tableHeadFields[7].isShow"
        :label="tableHeadFields[7].label"
        :width="tableHeadFields[7].width"
        :align="tableHeadFields[7].align"
        :prop="tableHeadFields[7].prop" />
      <el-table-column
        v-if="tableHeadFields[8].isShow"
        :label="tableHeadFields[8].label"
        :width="tableHeadFields[8].width"
        :align="tableHeadFields[8].align"
        :prop="tableHeadFields[8].prop" />
      <el-table-column
        v-if="tableHeadFields[9].isShow"
        :label="tableHeadFields[9].label"
        :width="tableHeadFields[9].width"
        :align="tableHeadFields[9].align"
        :prop="tableHeadFields[9].prop">
        <template slot-scope="scope">
          {{ scope.row.status ? '启用' : '停用' }}
        </template>
      </el-table-column>
      <el-table-column label="操作" :width="300" align="center" fixed="right">
        <template slot-scope="scope">
          <div class="gz-text-btn-group">
            <div
              v-has-permi="[permissions.update]"
              class="gz-text-btn gz-text-btn-yellow"
              @click="edit(scope.row, '设置优免', beforeEdit(scope.row, 1))">
              设置优免
            </div>
            <div
              v-has-permi="[permissions.update]"
              class="gz-text-btn gz-text-btn-yellow"
              @click="edit(scope.row, '签约商户', beforeEditShop(scope.row, 2))">
              签约商户
            </div>
            <div
              v-has-permi="[permissions.delete]"
              class="gz-text-btn gz-text-btn-red"
              @click="handleDelete(scope.row.mchId, '删除优免', handleDel)">
              删除
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- end 数据Table -->

    <!-- 添加修改dialog -->
    <el-dialog
      :title="modalObject.title"
      :visible.sync="modalObject.status"
      append-to-body
      width="868px"
      class="gz-common-dialog">
      <el-form ref="form" label-width="100px" :model="ymData" :rules="rules">
        <el-row v-if="dialogShow === 1" :gutter="20">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="商户名称:" prop="name">
                <el-input v-model="ymData.name" disabled />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系方式:" prop="phone">
                <el-input v-model="ymData.phone" disabled />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="商户地址:" prop="address">
                <el-input v-model="ymData.address" disabled />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-divider />
            </el-col>
          </el-row>
          <el-col :span="11">
            <el-form-item label="减免金额" prop="limitPrice">
              <el-input-number v-model="ymData.limitPrice" controls-position="right" :precision="2" />
            </el-form-item>
          </el-col>
          <el-col :span="1">
            <div style="margin-top: 10px; font-size: 14px">元</div>
          </el-col>
          <el-col :span="12">
            <el-form-item label="停车折扣" prop="settleDiscountScale">
              <el-input-number
                v-model="ymData.settleDiscountScale"
                controls-position="right"
                :precision="2"
                :step="0.01"
                :min="0"
                :max="1" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="dialogShow === 1" :gutter="20">
          <el-col :span="11">
            <el-form-item label="年免金" prop="yearExemption">
              <el-input-number v-model="ymData.yearExemption" controls-position="right" />
            </el-form-item>
          </el-col>
          <el-col :span="1">
            <div style="margin-top: 10px; font-size: 14px">元</div>
          </el-col>
          <el-col :span="11">
            <el-form-item label="门槛金额" prop="minConsumePrice">
              <el-input-number v-model="ymData.minConsumePrice" controls-position="right" />
            </el-form-item>
          </el-col>
          <el-col :span="1">
            <div style="margin-top: 10px; font-size: 14px">元</div>
          </el-col>
          <el-col :span="11">
            <el-form-item label="停车区域" prop="minConsumePrice">
              <el-select
                ref="select"
                v-model="ymData.parkingId"
                multiple
                placeholder="请选择停车区域"
                @change="bindSelectChange($event)">
                <el-option v-for="item in parkingData" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="dialogShow === 2" :gutter="20">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="商户名称:" prop="name">
                <el-input v-model="actionDataItem.name" disabled />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="商户地址:" prop="address">
                <el-input v-model="actionDataItem.address" disabled />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-divider />
            </el-col>
          </el-row>
          <el-form-item>
            <el-button type="success" @click="handleAddRoomItem()">添加商铺</el-button>
          </el-form-item>
          <el-form-item label="">
            <TableSelect ref="tabselect" class="m-b-20" :memberlist1="memberlist2" :tablecolumns="tablecolumns" />
          </el-form-item>
        </el-row>
        <el-row v-if="dialogShow === 2" :gutter="20">
          <el-col :span="24">
            <el-divider />
          </el-col>
        </el-row>
        <el-row v-if="dialogShow === 2" :gutter="20">
          <el-col :span="12">
            <el-form-item label="开始时间:" prop="periodStart">
              <el-date-picker v-model="actionDataItem.periodStart" type="date" placeholder="选择日期" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="签约月数" prop="periodMonths">
              <el-input-number v-model="actionDataItem.periodMonths" controls-position="right" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="dialogShow === 2" :gutter="20">
          <el-col :span="12">
            <el-form-item label="押金:" prop="depositPrice">
              <el-input-number v-model="actionDataItem.depositPrice" controls-position="right" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="单月租金" prop="rentPerMonth">
              <el-input-number v-model="actionDataItem.rentPerMonth" controls-position="right" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="dialogShow === 2" :gutter="20">
          <el-col :span="12">
            <el-form-item label="签约类型:" prop="signType">
              <el-select v-model="actionDataItem.signType">
                <el-option label="购买" value="1" />
                <el-option label="出租" value="2" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row v-if="dialogShow === 3" :gutter="20">
          <el-row :gutter="20">
            <el-form-item label="商铺全名:" prop="fullNumber">
              <el-input v-model="dialogFormData.fullNumber" disabled />
            </el-form-item>
            <el-col>
              <p>"确定作废此商铺签约？这可能包含同一次签约下的多个商铺"</p>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-divider />
            </el-col>
          </el-row>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <!-- end 添加修改dialog -->

    <!-- 分页模块 -->
    <pagination v-show="total > 0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" />
    <!-- end 分页模块 -->
  </div>
</template>
<script>
  import Pagination from '@/views/mixins/pagination'
  import Filter from '@/views/mixins/filter'
  import ModalMixins from '@/views/mixins/modal'
  import CommonInfo from '@/views/mixins/commonInfo'
  import ContentHeader from '@/components/ContentHeader'
  import TableSelect from '@/components/TableSelect'
  import { list, add, deleteItem } from '@/api/consumeLimit' // eslint-disable-line
  import { merchantList, merchantPromotion, merchantSign, merchantCancel } from '@/api/merchantList'
  import { shopList } from '@/api/shop'
  const { export_json_to_excel } = require('../../../vendor/Export2Excel')

  export default {
    name: 'MerchantList',

    components: { ContentHeader, TableSelect },

    mixins: [Pagination, Filter, ModalMixins, CommonInfo],

    data() {
      return {
        parkIDdata: [],
        parkingData: [],
        memberlist2: [],
        value1: '',
        tablecolumns: [
          {
            prop: 'fullNumber',
            label: '商铺全号'
          },
          {
            prop: 'buildingId',
            label: '楼栋ID'
          },
          {
            prop: 'areaTotal',
            label: '商铺面积'
          },
          {
            prop: 'floor',
            label: '楼层'
          },
          {
            prop: 'unit',
            label: '单元号'
          }
        ],

        processIds: [],

        /**
         * 账号添加修改表单数据绑定
         */
        formData: {
          id: '',
          name: '',
          limitPrice: '',
          level: '',
          address: '',
          phone: '',
          settleDiscountScale: 1,
          yearExemption: '',
          minConsumePrice: ''
        },
        ymData: {
          name: '',
          phone: '',
          address: '',
          limitPrice: 0,
          settleDiscountScale: 1,
          yearExemption: 0,
          minConsumePrice: 0,
          parkingId: []
        },

        /**
         * 状态添加修改表单数据验证规则
         */
        rules: {
          // name: [{ required: true, message: '商户名称不能为空', trigger: 'blur' }],
          // limitPrice: [{ required: true, message: '减免金额不能为空', trigger: 'blur' }],
          // minConsumePrice: [{ required: true, message: '门槛金额不能为空', trigger: 'blur' }]
        },

        /**
         * contentHeader组件配置
         */
        settings: {
          left: {
            totalItemShow: true,
            searchShow: true,
            filterToggleShow: false,
            tableHeadToggleShow: false,
            searchPlaceholder: '商户名称'
          },
          right: {
            refreshShow: true,
            sortShow: false,
            addShow: false,
            exportDataShow: false,
            importDataShow: false,
            more: [
              // {
              //   label: '批量删除',
              //   permission: 'delete',
              //   emitEvent: 'batch-delete'
              // }
            ]
          },
          dropDownFilterArray: {
            arr: [
              {
                type: 'input',
                name: 'name',
                placeholder: '请输入商户名称'
              }
            ]
          }
        },
        dialogShow: ' ',
        /**
         * 表格显示字段信息
         */
        tableHeadFields: [
          {
            label: '商户名称',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'name'
          },
          {
            label: '联系方式',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'phone'
          },
          {
            label: '商户地址',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'address'
          },
          {
            label: '商户级别',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'mainBind'
          },
          {
            label: '优免金额(元)',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'limitRule.limitPrice'
          },
          {
            label: '停车折扣',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'settleRule.discountScale'
          },
          {
            label: '年免金(元)',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'settleRule.yearExemption'
          },
          {
            label: '门槛金额(元)',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'limitRule.minConsumePrice'
          },
          {
            label: '添加时间',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'limitRule.createTime'
          },
          {
            label: '状态',
            isShow: true,
            width: '200',
            align: 'center',
            prop: 'status'
          }
        ],

        /**
         * 权限信息
         */
        permissions: {
          create: 'comsume-limit:create',
          info: 'comsume-limit:info',
          delete: 'comsume-limit:delete',
          update: 'comsume-limit:update',
          export: 'comsume-limit:export',
          import: 'comsume-limit:import'
        },

        dialogFormData: {
          id: 34,
          name: 'hello',
          tableData: [
            {
              name: 'name1',
              id: 'id1',
              unit: 'unit1',
              content: '',
              createDate: '',
              updateDate: ''
            },
            {
              name: 'name2',
              id: 'id2',
              unit: 'unit1',
              content: '',
              createDate: '',
              updateDate: ''
            }
          ]
        }
      }
    },

    mounted() {
      this.parkingData.splice(0, 0, ...JSON.parse(localStorage.getItem('GZ-ParkingList')))
      console.log(this.parkingData)
      this.getList()
      this.getShopList()
    },

    methods: {
      /**
       * 切换优免规则状态
       */
      handleSwitchStatus(data) {
        const postData = {}
        postData.enable = !data.enable
        postData.parkingId = this.pid
        postData.limitPrice = data.ruleInfo.limitPrice
        postData.settleDiscountScale = data.settleDiscountScale
        postData.yearExemption = data.yearExemption
        postData.mchId = data.mchId
        add(postData)
          .then((res) => {
            this.noticeSuccess('修改成功')
            this.handleRefresh()
          })
          .catch((e) => {
            this.noticeError(e.message)
          })
      },

      bindSelectChange(e) {
        console.log(e, '=====')
        this.parkIDdata = e
        console.log(e, '------选择的')
      },

      /**
       * 编辑前执行
       */
      beforeEditDiscard(data, e) {
        this.dialogShow = e
        this.actionDataItem = data
        this.dialogFormData = data
      },

      /**
       * 编辑前执行
       */
      beforeEditShop(data, e) {
        this.dialogShow = e
        this.actionDataItem = data
        this.dialogFormData.tableData = data.refShops
      },

      /**
       * 编辑前执行
       */
      beforeEdit(data, e) {
        this.dialogShow = e
        this.ymData = {
          id: data.id,
          name: data.name,
          phone: data.phone,
          address: data.address,
          limitPrice: data.limitRule && data.limitRule.limitPrice ? data.limitRule.limitPrice : 0,
          settleDiscountScale:
            data.settleRule && data.settleRule.settleDiscountScale ? data.settleRule.settleDiscountScale : 1,
          yearExemption: data.settleRule && data.settleRule.yearExemption ? data.settleRule.yearExemption : 0,
          minConsumePrice: data.limitRule && data.limitRule.minConsumePrice ? data.limitRule.minConsumePrice : 0,
          parkingId: data.settleRule && data.settleRule.parkingId ? [data.settleRule.parkingId] : []
        }
      },

      /**
       * 优免商户列表变动事件
       */
      handleMerchantChange(value) {
        const _this = this
        const currentItem = this.merchantList.find((item) => item.id === value)
        this.actionDataItem.address = currentItem.address
        this.actionDataItem.phone = currentItem.phone
        if (currentItem.mainBindCommunity.length === 0) {
          this.actionDataItem.level = '商户数据异常'
        } else {
          if (currentItem.mainBindCommunity.communityId === this.communityId) {
            this.actionDataItem.level = '主选区域'
            return
          }
          const secondBind = currentItem.secondCommunityList.find((item) => {
            return item.communityId === _this.communityId
          })

          if (secondBind.length > 0) {
            this.actionDataItem.level = '附属区域'
          } else {
            this.actionDataItem.level = '商户数据异常'
          }
        }
      },

      /**
       * 获取商铺列表
       */
      getShopList() {
        const _this = this
        const queryData = {}
        queryData.communityId = this.communityId
        queryData.signed = false
        queryData.current = 1 // this.currentPage
        queryData.size = 999 // this.pageSize
        shopList(queryData)
          .then((res) => {
            _this.memberlist2 = res.data.records
          })
          .catch((e) => {
            _this.noticeError(e.message)
          })
      },

      /**
       * 获取列表数据
       */
      getList() {
        const _this = this
        this.loading = true

        // 组装查询条件
        const queryData = {}
        queryData.createTimeStart = ''
        queryData.createTimeEnd = ''
        queryData.communityId = this.communityId
        this.filterData.searchStr && (queryData.kw = this.filterData.searchStr)
        merchantList(_this.pid, queryData)
          .then((res) => {
            if (res.result) {
              _this.dataList = res.data.records
              _this.total = res.data.total
            } else {
              _this.noticeError(res.message)
            }
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
          .finally(() => {
            _this.loading = false
          })
      },

      /**
       * 刷新页面
       */
      handleRefresh() {
        this.getList()
      },

      /**
       * 提交信息
       */
      submitForm() {
        console.log(this.actionDataItem, '------')
        const _this = this
        this.$refs.form.validate((valid) => {
          if (valid) {
            if (_this.dialogShow === 1) {
              const postData = {
                limitPrice: _this.ymData.limitPrice,
                mchId: _this.ymData.id,
                minConsumePrice: _this.ymData.minConsumePrice,
                // parkingId: _this.pid,
                parkingId: this.ymData.parkingId.join(','),
                settleDiscountScale: _this.ymData.settleDiscountScale,
                yearExemption: _this.ymData.yearExemption,
                enable: 1
              }
              console.log(postData, '-----入参')
              merchantPromotion(postData)
                .then((response) => {
                  _this.noticeSuccess('修改优免成功')
                  _this.modalObject.status = false
                  _this.handleRefresh()
                })
                .catch((err) => {
                  _this.noticeError(err.message)
                })
            } else if (_this.dialogShow === 2) {
              // 新签约商铺
              const ids = this.$refs.tabselect.dataForm.processDefinitionId
              const postData = {
                communityId: _this.communityId,
                depositPrice: _this.actionDataItem.depositPrice,
                opAid: 0,
                periodMonths: _this.actionDataItem.periodMonths,
                periodStart: _this.actionDataItem.periodStart,
                rentPerMonth: _this.actionDataItem.rentPerMonth,
                shopIds: ids,
                signType: _this.actionDataItem.signType
              }
              merchantSign(_this.actionDataItem.id, postData)
                .then((res) => {
                  _this.noticeSuccess('签约成功')
                  _this.modalObject.status = false
                  _this.handleRefresh()
                })
                .catch((err) => {
                  _this.noticeError(err.message)
                })
            } else {
              // 签约作废
              const signId = _this.dialogFormData.refSignId
              const postData = {
                cancelReason: '作废'
              }
              merchantCancel(signId, postData)
                .then((res) => {
                  _this.noticeSuccess('解约成功')
                  _this.modalObject.status = false
                  _this.handleRefresh()
                })
                .catch((err) => {
                  _this.noticeError(err.message)
                })
            }
          }
        })
      },

      /**
       * 删除一条记录
       */
      handleDel(id) {
        const _this = this
        const postData = {}
        postData.parkingId = this.pid
        deleteItem(id, postData)
          .then((res) => {
            _this.handleRefresh()
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
      },

      /**
       * 动态删除已签约商铺
       */
      handleDelSignedShop(id) {
        const _this = this
        _this.dialogFormData.tableData.splice(id, 1)
      },

      /**
       * 批量删除记录
       */
      handleBatchDel(ids) {
        // const _this = this
        const idArr = []
        ids.forEach((item) => {
          idArr.push(item.id)
        })
        const postData = {}
        postData.ids = idArr.join(',')
        // batchDelete(postData).then(res => {
        //   _this.handleRefresh()
        // }).catch(err => {
        //   _this.msgError(err.message)
        // })
      },

      /**
       * 导出数据
       */
      handleExport() {
        const _this = this
        this.$confirm('是否导出商户优免名单?', '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            // 组装查询条件
            const queryData = {}
            queryData.current = this.currentPage
            queryData.size = this.pageSize
            queryData.parkingId = this.pid
            this.filterData.searchStr && (queryData.kw = this.filterData.searchStr)
            list(queryData)
              .then((res) => {
                require.ensure([], () => {
                  const tHeader = []
                  const filterVal = []
                  this.tableHeadFields.forEach((item) => {
                    tHeader.push(item.label)
                    filterVal.push(item.prop)
                  })
                  const data = res.data.orders.map((v) => {
                    return filterVal.map((j) => {
                      if (j === 'startTime') {
                        return _this.parseTime(v[j])
                      } else if (j === 'endTime') {
                        return _this.parseTime(v[j])
                      } else {
                        return v[j]
                      }
                    })
                  })
                  export_json_to_excel(tHeader, data, '商户优免名单列表')
                })
                _this.noticeSuccess('导出成功')
              })
              .catch((err) => {
                _this.noticeError(err.message)
              })
          })
          .catch((err) => {
            _this.noticeError(err.message)
          })
      }
    }
  }
</script>
<style lang="scss" scoped>
  ::v-deep .el-input-number {
    width: 100%;
  }
  ::v-deep .el-input__inner {
    text-align: left;
  }
</style>
