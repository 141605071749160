<!--
#***********************************************
#
#      Filename: src/views/List/MerchantList.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 下拉选择表
#        Create: 2021-10-28 16:40:15
# Last Modified: 2021-11-29 18:03:01
#***********************************************
-->
<template>
  <el-form
    id="selecTable"
    ref="dataForm"
    :model="dataForm"
    :rules="dataRule"
    label-width="120px"
    @keyup.enter.native="dataFormSubmit()">
    <el-select
      ref="select"
      v-model="dataForm.processDefinitionId"
      placeholder="请选择"
      multiple
      size="medium"
      @change="handselect"
      @click.native="deptogglePanel($event)">
      <el-option v-for="(item, index) in processDefinition" :key="index" :label="item.name" :value="item.id" />
    </el-select>
    <div v-if="showTree" ref="tableList" class="treeDiv">
      <el-input v-model="ss" placeholder="搜索" size="medium" @input="handinput" />
      <el-table
        ref="memberTable"
        :data="memberList"
        border
        :row-key="getRowKeys"
        :cell-style="getCellStyle"
        :header-cell-style="getHeaderCellStyle"
        @select="handleSelectClick"
        @row-click="handleRegionNodeClick"
        @selection-change="handleChange"
        @select-all="selectAll">
        <el-table-column type="selection" header-align="center" align="center" :reserve-selection="true" width="50" />
        <el-table-column
          v-for="(item, index) in Columns"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :show-overflow-tooltip="true" />
      </el-table>
    </div>
  </el-form>
</template>

<script>
  export default {
    name: 'TableSelect',
    props: {
      memberlist1: {
        type: Array,
        default() {
          return []
        }
      },
      tablecolumns: {
        type: Array,
        default() {
          return []
        }
      }
    },
    data() {
      return {
        ss: '',
        visible: false,
        isDisabled: false,
        dataForm: {
          termName: 'project test', // 项目名称
          processDefinitionId: []
        },
        dataRule: {
          processDefinitionId: [
            {
              required: true,
              message: '请选择项目',
              trigger: 'change'
            }
          ],
          termName: [
            {
              required: true,
              message: '项目名称不能为空',
              trigger: 'blur'
            }
          ]
        },
        arr: [],
        processDefinition: [],

        // 保留数据
        memberList0: [
          {
            id: 'id-1',
            number: 'number-1',
            typename: 'typename-1',
            efilename: 'efilename-1',
            version: 'version-1',
            name: 'mem name1'
          },
          {
            id: 'id-2',
            number: 'number-2',
            typename: 'typename-2',
            efilename: 'efilename-2',
            version: 'version-2',
            name: 'mem name2'
          },
          {
            id: 'id-3',
            number: 'number-3',
            typename: 'typename-3',
            efilename: 'efilename-3',
            version: 'version-3',
            name: 'mem name3'
          }
        ],
        // 流程模板下拉框
        memberList: [
          {
            id: 'id-1',
            number: 'number-1',
            typename: 'typename-1',
            efilename: 'efilename-1',
            version: 'version-1',
            name: 'mem name1'
          },
          {
            id: 'id-2',
            number: 'number-2',
            typename: 'typename-2',
            efilename: 'efilename-2',
            version: 'version-2',
            name: 'mem name2'
          },
          {
            id: 'id-3',
            number: 'number-3',
            typename: 'typename-3',
            efilename: 'efilename-3',
            version: 'version-3',
            name: 'mem name3'
          }
        ], // list
        showTree: false,
        Columns: [],
        getRowKeys(row) {
          return row.id
        },
        multipleSelection: [],
        isShowSelect: true
      }
    },
    watch: {
      isShowSelect(val) {
        // 隐藏select自带的下拉框
        this.$refs.select.blur()
      }
    },
    created() {},
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          this.isDisabled = false
          this.arr = []
          this.multipleSelection = []
          this.memberList = this.memberlist1 // 表格赋值
          if (!this.dataForm.id) {
            // 新增
          } else {
            this.memberList = this.memberlist1
            this.Columns = this.tablecolumns
            this.multipleSelection = []
            this.rowMultipleChecked(this.multipleSelection)
          }
        })
      },

      // 表格css
      getCellStyle() {
        return 'text-align:center;'
      },

      getHeaderCellStyle() {
        return 'background: rgba(9, 37, 56,0.1);text-align:center; background: linear-gradient(to bottom,#ffffff 0,#eeeeee 100%);padding: 4px 5px;'
      },

      // 点击input 阻止冒泡 控制table显示隐藏
      deptogglePanel(event) {
        this.isShowSelect = !this.isShowSelect // 隐藏select本来的下拉框
        event || (event = window.event)
        event.stopPropagation ? event.stopPropagation() : (event.cancelBubble = true)
        this.showTree ? this.tableHide() : this.tableShow()
      },

      // 显示表格
      tableShow() {
        this.showTree = true
        document.addEventListener('click', this.tableHideList, false)
        this.rowMultipleChecked(this.multipleSelection)
        this.memberList = this.memberlist1
        this.Columns = this.tablecolumns
      },

      // 隐藏表格
      tableHide() {
        this.showTree = false
        document.addEventListener('click', this.tableHideList, false)
        this.memberList = this.memberlist1
      },

      tableHideList(e) {
        if (this.$refs.tableList && !this.$refs.tableList.contains(e.target)) {
          this.tableHide()
        }
      },

      // 点击table节点
      handleRegionNodeClick(data) {
        this.showTree = true
      },

      // 多选
      handleSelectClick(data) {
        this.showTree = true
      },

      // 全选
      selectAll(data) {
        this.showTree = true
      },

      // selection-change表格多选框变化事件
      handleChange(data) {
        // 表格中选中的行
        this.arr = []
        for (const i in data) {
          this.arr.push(data[i].id)
          this.processDefinition.push({
            id: data[i].id,
            name: data[i].fullNumber
          })
        }
        this.dataForm.processDefinitionId = this.arr // select赋值
        this.multipleSelection = data // 勾选放在multipleSelection数组中
      },

      // 表格多选框选中判断
      rowMultipleChecked(multipleSelection) {
        console.log(multipleSelection)
        if (multipleSelection != null) {
          for (let j = 0; j < multipleSelection.length; j++) {
            for (let i = 0; i < this.memberList.length; i++) {
              if (multipleSelection[j].id === this.memberList[i].id) {
                // 如果在后端传来的值中id存在则选中多选框
                this.$nextTick(() => {
                  // 必写
                  if (this.$refs.memberTable !== undefined) {
                    // this.$refs.memberTable.toggleRowSelection(this.memberList[i], true)
                  }
                })
              }
            }
          }
        }
      },

      // 删除文件档案
      handselect(value) {
        // select和表格相关联
        let data = this.multipleSelection
        const arr = []
        if (value.length > 0) {
          // 删除multipleSelection（选中的所有值）中的value
          for (let j = 0; j < data.length; j++) {
            if (value.indexOf(data[j].id) === -1) {
              data.splice(j, 1)
            }
          }
          this.multipleSelection = data
        } else {
          this.multipleSelection = []
          data = []
        }
        for (const s in data) {
          arr.push(data[s].id)
        }
        if (arr != null) {
          // 需要判断那些值需要取消选中
          for (let i = 0; i < this.memberList.length; i++) {
            if (arr.indexOf(this.memberList[i].id) === -1) {
              this.$refs.memberTable.toggleRowSelection(this.memberList[i], false)
            }
          }
        }
      },

      // 搜索
      handinput() {
        const _this = this
        this.tableShow()

        const resultData = this.memberList.filter((data) => {
          var property
          for (property in data) {
            console.log('属性名称:' + property + '' + '属性的值:' + data[property])
            if (typeof data[property] === 'string' && data[property].indexOf(_this.ss) !== -1) {
              return true
            }
          }
        })
        this.memberList = resultData
      },

      // 表单提交
      dataFormSubmit() {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            if (this.dataForm.id === '') {
              this.isDisabled = true
            }
            this.dataForm.id = this.dataForm.id || undefined
          }
        })
      }
    }
  }
</script>

<style>
  .applicaWord .el-upload-list__item .el-icon-close-tip {
    display: none !important;
  }
  .treeDiv {
    position: absolute;
    top: 52px;
    left: -1px;
    z-index: 1000;
    width: 100%;
    overflow: auto;
    max-height: 280px;
    /* border: 1px solid #ccc; */
    border-radius: 6px;
    background: #ffffff;
  }

  .treeDiv::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 4px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 4px;
  }

  .treeDiv::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
  }

  .treeDiv::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
  }

  .treeDiv .el-table {
    font-size: 14px;
  }

  .treeDiv .el-table /deep/ td {
    padding: 4px 0;
  }

  #selecTable .el-select {
    width: 100%;
  }

  #selecTable .el-input {
    width: 100%;
  }

  #kuan .el-form-item__content {
    width: 80%;
  }
</style>
