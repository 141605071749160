/*
 *                        .::::.
 *                      .::::::::.
 *                     :::::::::::
 *                  ..:::::::::::'
 *               '::::::::::::'
 *                 .::::::::::
 *            '::::::::::::::..
 *                 ..::::::::::::.
 *               ``::::::::::::::::
 *                ::::``:::::::::'        .:::.
 *               ::::'   ':::::'       .::::::::.
 *             .::::'      ::::     .:::::::'::::.
 *            .:::'       :::::  .:::::::::' ':::::.
 *           .::'        :::::.:::::::::'      ':::::.
 *          .::'         ::::::::::::::'         ``::::.
 *      ...:::           ::::::::::::'              ``::.
 *     ````':.          ':::::::::'                  ::::..
 *                        '.:::::'                    ':'````..
 *
 * @Author       : wwj 318348750@qq.com
 * @Date         : 2022-03-28 19:33:28
 * @LastEditors  : wwj 318348750@qq.com
 * @LastEditTime : 2022-07-16 11:01:20
 * @FilePath     : \gz-report-front\src\api\shop\index.js
 * @Description  : des
 */

/*
#***********************************************
#
#      Filename: src/api/shop/index.js
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 商铺api接口
#        Create: 2021-11-10 15:27:39
# Last Modified: 2021-11-27 21:16:36
#***********************************************
*/
import request from '@/utils/request'

// 商铺列表
export function shopList(data) {
  return request({
    url: '/housing/shop/list',
    method: 'get',
    params: data
  })
}

// 创建商铺
export function shopAdd(id, data) {
  return request({
    url: '/housing/shop/create/' + id,
    method: 'post',
    data: data
  })
}

// 创建商铺
export function shopUpdate(shopId, data) {
  return request({
    url: '/housing/shop/update/' + shopId,
    method: 'post',
    data: data
  })
}

// 批量导入商铺列表
export function importBatchList(data) {
  return request({
    url: '/housing/shop/importBatch/list',
    method: 'get',
    params: data
  })
}
export function exportErrorRecords(id) {
  return request({
    url: '/housing/shop/importBatch/' + id + '/failRecords/export',
    method: 'get',
    responseType: 'blob'
  })
}

// 房屋删除
export function deleteItem(id) {
  return request({
    url: '/housing/shop/delete/' + id,
    method: 'post'
  })
}
